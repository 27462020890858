.form__field-error {
  color: red;
}
// border-bottom: 1px solid #d8d8d8 !important;
// .error-input {
//     width: 400px !important;
// }

.topright {
  position: absolute !important;
  top: 2px;
  right: 8px;
  font-size: 18px;
}

.bottomright {
  position: absolute !important;
  bottom: 2px;
  right: 4px;
  font-size: 18px;
}
