.rigthNav {
    border: 1px solid;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}
.rigthNav > li{
    position:relative;
    span.badge {
        background: #000;
        color: #f9d769;
        position: absolute;
        top: -8px;
        right: -1px;
        z-index: 10;
    }
}
.rigthNav > li .noticeDrop{
    position:absolute;
    width: 250px;
    height:250px;
    background:#fff;
    right: 8px;

    top: 115%;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    overflow: hidden;
    border: 2px solid #ccc;

    &.isHide{
        display: none;
    }
}

ul.alertList {
    list-style: none;
    margin: 0;
    padding: 0 1px 0 0px;
    height: 220px;
    overflow-y: auto;
}

ul.alertList li a.btnView{
    color: #fff;
    font-size: 11px;
    border: 1px solid #5e72e4;
    background: #5e72e4;
    border-radius: 2px;
    padding: 1px 4px;
    line-height: 15px;
}
ul.alertList li{
    border-bottom: 1px solid #fff;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    padding: 6px 10px 6px 10px;
    align-items: center;
    background: #eee; 
    transition: all .25s;
    span{
        padding-right: 5px;
    }
    &:hover{
        background: #000;
        color: #f9d769;
    }  
    &.hasSeen{
        background: #fff;
        border-bottom: 1px solid #eee;
        color: #333;

        a.btnView{
            border: 1px solid #ccc;
            background: #ccc;
        }
    } 
}
.viewAll{
    width: 100%;
    display: flex;
    height: 31px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    background: #5e72e4;
    color: #fff !important;
    cursor: pointer;
    transition: all .35s;
    margin-bottom: 2px;

    &:hover{
        background: #ffd600;
        color: #000 !important;
        padding-bottom: 7px;
    }
}