.table-tr {
  cursor: pointer;
}
.table-tr:hover {
  // cursor: pointer;
  background-color: #171f29;
  color: #f7f8fd;
}
.customPermissions {
  border: 1px solid #141923;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: #555354;
  padding: 4px;
}

.ui.modal > .content {
  display: flex;
  align-items: center;
}
