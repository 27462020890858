.modal_confirmation {
  z-index: 1066 !important;
  display: block;
}
.modal2 {
  z-index: 1066 !important;
  display: block;
}
.ui.page.modals.dimmer.transition.visible.active {
  z-index: 1066 !important;
  display: block;
}
.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
