.table-tr {
  cursor: pointer;
}
.table-tr:hover {
  // cursor: pointer;
  background-color: #171f29;
  color: #f7f8fd;
}
.customPermissions {
  border: 1px solid #141923;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  background-color: #555354;
  padding: 4px;
}
.selectSearchOption {
  display: flex;

  input.form-control {
    max-width: 500px;
    // margin-right: 10px;
  }
  .btnMamagePermission {
    margin-left: 10px;
    border-radius: 2px;
    height: 40px;
    margin-right: 0px;
  }
}
.selectSearchOption1 {
  display: flex;
  justify-content: flex-end;

  input.form-control {
    max-width: 500px;
    // margin-right: 10px;
  }
  .btnMamagePermission {
    margin-left: 10px;
    border-radius: 2px;
    height: 40px;
    margin-right: 0px;
  }
}

.modalCustom {
  .modal-content {
    background: #171f29;
    border-radius: 8px;
  }
  .close {
    opacity: 1;
  }
  .close > span:not(.sr-only) {
    font-size: 30px;
    color: #f9d769;
  }
  .modal-header,
  .modal-body,
  .modal-footer {
    // background: #171f29;
  }
  .modal-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    h1 {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
.itemStyle1 {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.itemStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  label {
    font-size: 12px;
    color: #fff;
    margin-bottom: 0;
  }
  span {
    color: #fff;
    font-size: 12px;
  }
  h1 {
    color: #fff;
    // font-size: 12px;
  }
}

.userNameList {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}
.userNameList > div {
  padding-left: 10px;
  padding-right: 10px;
}
.userNameList > div:nth-child(even) {
  background: rgba(255, 255, 255, 0.1);
}
.table.table_customStyle {
  background: rgba(255, 255, 255, 0.05);
  tr {
    &:nth-child(even) {
      background: rgba(255, 255, 255, 0.1);
    }
    th,
    td {
      padding-top: 8px;
      padding-bottom: 8px;
      color: #fff;
      font-size: 12px;
      border: 0;
    }

    th {
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 400;
      color: #f9d769 !important;
      background-color: black !important;
    }
  }
}

.popUpClose {
  position: absolute;
  top: 20px;
  right: 20px;
}
.popUpClose span {
  color: #fff !important;
  opacity: 1 !important;
  font-size: 25px !important;
}

.authPopUpBody.modal-body .card {
  background: #171f29;
  border: 0px;
  text-align: center;
  padding-top: 0;
}
.authPopUpBody.modal-body .card .row {
  flex-wrap: wrap;
}
.authPopUpBody.modal-body .card .row .col {
  flex-basis: auto;
  color: #fff;
  text-align: center;
}
.authPopUpBody.modal-body .card .row .col img {
  height: 55px;
}

.authPopUpBody.modal-body .card .row .col:nth-child(2),
.authPopUpBody.modal-body .card .row .col:nth-child(3) {
  width: 50%;
  margin-bottom: 20px;
}
.authPopUpBody.modal-body .card .row .col:nth-child(4) .card-title {
  margin-bottom: 0px;
}
.authPopUpBody.modal-body .btn.btn-info {
  width: 100%;
}
.authList .card {
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  max-width: 50%;
}
.authList .card img {
  width: auto;
}
.authList .card .card-title {
  margin-bottom: 0;
  flex: 1;
  padding-left: 15px;
}
.authList .card .btn-info {
  max-width: 180px;
  width: 100%;
}
.footer {
  display: none;
}
.authPopUpBody {
  text-align: center;
}
.authPopUpBody .one.column.row.stepTwo {
  justify-content: center;
  box-sizing: border-box;
  padding: 25px;
}

.authPopUpBody .one.column.row.stepTwo h5.ui.center.aligned.header {
  background: transparent !important;
  padding: 25px 0;
}
.authPopUpBody .btn-info {
  width: auto !important;
}
// .ui.form .field > label {
//   display: flex;
//   color: #fff !important;
// }
