.coinSelect {
  .selection {
    background: #38a5cf !important;
    border-radius: 0 !important;

    > .text,
    > .default.text,
    &.visible.dropdown > .text:not(.default) {
      color: #fff !important;
    }
  }
}

.athunList {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px !important;
  padding-top: 25px !important;
  border: 1px solid #efefef;
  margin-bottom: 25px;
  padding: 25px;
}
.athunList:after {
  display: none !important;
}
.athunList .ui.button {
  border-radius: 0px;
  min-width: 150px;
}
.athunList .ui.button.is_active {
  background: #0172ce !important;
  color: #fff !important;
}
.athunList > div:nth-child(2) {
  width: calc(100% - 250px);
  box-sizing: border-box;
  padding-right: 45px;
}
.athunList > div:nth-child(2) input {
  font-size: 14px !important;
  padding: 10px 15px !important;
}
.athunList .header {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
  color: #333 !important;
}

.pd0 {
  padding: 0px !important;
}
.mr0 {
  margin: 0px !important;
}
.ui.positive.button,
.ui.positive.buttons .button {
  background: #0172ce !important;
  color: #fff !important;
  border-radius: 0px;
}
.btn-info {
  width: 69%;
}

.image-btn {
  width: 90%;
}
