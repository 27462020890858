.mainLogin {
  height: 100vh;
  background: #eef2ff;
  display: flex;
  align-items: center;

  img {
    width: 80px;
  }
  h3 {
    margin-top: 10px;
    color: #004ea7;
  }
}
.loginBlock .card-body {
  border-radius: 10px;

  .btn-primary {
    color: #fff;
    background-color: #004ea7;
    border-color: #004ea7;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    text-transform: uppercase;
    min-width: 150px;
  }
}
.spinner-border {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
}
.logoBlock {
  background: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;

  img.insideLogo {
    width: 37px;
    margin-right: 10px;
  }
  p {
    color: #0057b7;
    font-weight: 700;
    margin-bottom: 0;
  }
}
.main-content .container-fluid.contentBlock,
.contentBlock {
  padding: 25px !important;
  box-sizing: border-box;
}
.contentBlock12 {
  padding: 25px 25px 0 25px !important;
  box-sizing: border-box;
}
.input-group-alternative div:nth-child(2) {
  width: calc(100% - 35px);
}
